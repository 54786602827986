import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  inject,
  PLATFORM_ID,
  afterRender,
} from "@angular/core";
import { NzDrawerService } from "ng-zorro-antd/drawer";
import { NzModalService } from "ng-zorro-antd/modal";
import { ShopApiService } from "@app/api/shop";
import { ShopSelectorComponent } from "@app/components/shop";
import { TranslateHelperService } from "@app/helpers/translate-helper";
import { LocaleSelectorComponent } from "@app/helpers/translate-helper/components/locale-selector/locale-selector.component";
import { Observable, of, switchMap } from "rxjs";

import { CartApiService } from "@app/api/cart";
import { SideBarComponent } from "../side-bar/side-bar.component";
import { TranslateService } from "@ngx-translate/core";
import { StateHelperService } from "@app/helpers/state";
import { AuthCoreService } from "@app/api/auth";
import { NotificationsApiService } from "@app/api/notifications";
import { Member } from "@app/api/member/models";
import { isPlatformBrowser } from "@angular/common";
@Component({
  selector: "nav-bar",
  templateUrl: "./nav-bar.component.html",
  styleUrls: ["./nav-bar.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavBarComponent implements OnInit {
  localeId$;
  shop$;
  notificationCount;
  cart$;
  livemode$;
  member$?: Observable<Member>;
  private platformId = inject(PLATFORM_ID);
  private stateService?: StateHelperService;
  private modalService?: NzModalService;
  private drawerService?: NzDrawerService;
  private translateHelper?: TranslateHelperService;
  private shopService?: ShopApiService;
  private notificationsService?: NotificationsApiService;
  private cartService?: CartApiService;
  private translateService?: TranslateService;
  private authService?: AuthCoreService;

  constructor() {
    if (isPlatformBrowser(this.platformId)) {
      this.translateHelper = inject(TranslateHelperService);
      this.translateService = inject(TranslateService);
      this.stateService = inject(StateHelperService);
      this.modalService = inject(NzModalService);
      this.drawerService = inject(NzDrawerService);
      this.shopService = inject(ShopApiService);
      this.notificationsService = inject(NotificationsApiService);
      this.cartService = inject(CartApiService);
      this.authService = inject(AuthCoreService);

      this.localeId$ = this.translateHelper.currentLocale$;
      this.shop$ = this.shopService.shop$;

      this.notificationCount = this.notificationsService.total;
      this.cart$ = this.cartService.cart$;
      this.livemode$ = this.stateService.livemode$;
      this.member$ = this.authService.authUser$;
    }
  }

  ngOnInit(): void {}

  changeLocale(): void {
    const title = this.translateService?.instant(
      "locale.language.choose_language"
    );
    this.modalService?.create({
      nzTitle: title,
      nzContent: LocaleSelectorComponent,
      nzFooter: [],
    });
  }

  showShopSelector(): void {
    const modal = this.drawerService?.create({
      nzContent: ShopSelectorComponent,
      nzContentParams: {
        autoSelect: false,
        syncList: true,
      },
      nzPlacement: "top",
    });

    modal?.afterOpen
      .pipe(
        switchMap(() => {
          const component = modal.getContentComponent();
          return component?.event ?? of(false);
        })
      )
      .subscribe({
        next: (data: any) => {
          if (data) {
            modal.close();
          }
        },
      });
  }

  showSidebar(): void {
    const drawer = this.drawerService?.create({
      nzContent: SideBarComponent,
      nzContentParams: {
        localeId$: this.localeId$,

        cart$: this.cart$,
      },
      nzPlacement: "left",

      nzCloseOnNavigation: true,
    });
  }
}
