import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import {
  ActivatedRoute,
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from "@angular/router";
import { BehaviorSubject, Observable, of } from "rxjs";
import { StateHelperService } from "@app/helpers/state";

@Injectable({
  providedIn: "root",
})
export class NavService {
  private history: string[] = [];
  private url$ = new BehaviorSubject<string>("");
  private hideTabBar$ = new BehaviorSubject<boolean>(false);
  private navbar$ = new BehaviorSubject<any>(null);
  private exemptPaths: string[] = [];
  private _loading$ = new BehaviorSubject<boolean>(false);

  loading$ = this._loading$.asObservable();

  private set loading(value: boolean) {
    this._loading$.next(value);
  }

  constructor(
    private router: Router,
    private location: Location,
    private stateService: StateHelperService
  ) {
    this.exemptPaths = ["settings"];

    this.router.events.pipe().subscribe({
      next: (event: Event) => {
        switch (true) {
          case event instanceof NavigationStart:
            this.loading = true;
            break;

          case event instanceof NavigationEnd:
            const e = event as NavigationEnd;
            this.url$.next(e.urlAfterRedirects);
            this.history.push(e.urlAfterRedirects);
            this.loading = false;
            break;

          case event instanceof NavigationCancel:
          case event instanceof NavigationError:
            this.loading = false;
            break;

          default:
            break;
        }
      },
    });
  }

  init(path: string) {
    if (path.startsWith("/test")) {
      this.stateService.livemode = false;
    }

    if (!path.startsWith("/test")) {
      this.stateService.livemode = true;
    }
  }

  resolve(): Observable<boolean> {
    const path = this.router.url;
    if (path.startsWith("/test")) {
      this.stateService.livemode = false;
      return of(false);
    }

    if (!path.startsWith("/test")) {
      this.stateService.livemode = true;
      return of(true);
    }

    return of(true);
  }

  watchUrl(): Observable<string> {
    return this.url$.asObservable();
  }

  watchTabBar(): Observable<boolean> {
    return this.hideTabBar$.asObservable();
  }

  navbar_watch(): Observable<any> {
    return this.navbar$.asObservable();
  }

  // pushRouteData(data: any): void {
  //   const hideTabBar = _.get(data, ['hideTabBar'], false);
  //   const navbar = _.get(data, ['navbar'], null);
  //   this.hideTabBar$.next(hideTabBar);
  //   this.navbar$.next(navbar);
  // }

  update_query(query: any, route: ActivatedRoute): void {
    this.router.navigate(["./"], {
      queryParams: query,
      relativeTo: route,
      queryParamsHandling: "merge",
    });
  }

  back(url?: string, check = true): void {
    if (url) {
      if (this.router.url.startsWith("/test") && check) {
        url = `/test${url}`;
      }
      this.router.navigateByUrl(url, { replaceUrl: true });
      this.history = [];
    } else if (this.history.length > 0) {
      this.history.pop();
      this.location.back();
    } else {
      this.router.navigateByUrl("/");
    }
  }

  to(url: string, blank = false): void {
    if (this.router.url.startsWith("/test")) {
      url = `/test${url}`;
    }
    if (blank) {
      window.open(url, "_blank");
    } else {
      this.router.navigateByUrl(url, { replaceUrl: true });
      this.history = [];
    }
  }

  reload(): void {
    const url = this.url$.value;
    window.location.href = url;
  }
}
