import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { RouterModule } from '@angular/router';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { TranslateHelperModule } from '../helpers/translate-helper';
import { TranslateModule } from '@ngx-translate/core';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { SideBarComponent } from './side-bar/side-bar.component'
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { AclCoreModule } from '../api/acl/acl.module';
import { LivemodeSelectorComponent } from './livemode-selector/livemode-selector.component';
import { FormsModule } from '@angular/forms';

import { NzImageModule } from 'ng-zorro-antd/image';


@NgModule({
  declarations: [
    HeaderComponent,
    NavBarComponent,
    SideBarComponent,
    LivemodeSelectorComponent,


  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,

    AclCoreModule,
    TranslateHelperModule,
    TranslateModule.forChild(),

    NzDrawerModule,
    NzModalModule,
    NzToolTipModule,
    NzBadgeModule,
    NzSwitchModule,
    NzImageModule
  ],
  exports: [
    HeaderComponent,
    NavBarComponent,
    SideBarComponent,
    LivemodeSelectorComponent,

  ]
})
export class LayoutsModule { }
