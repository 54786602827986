<div class="flex flex-col md:space-y-3 justify-end mr-4">
  <!-- shop selector -->
  <!-- <div class="flex-row items-center justify-end space-x-8 hidden md:flex">
    <ng-container *ngIf="member$ | async as member">
      <ng-container *ngIf="member.isAdmin">
        <livemode-selector></livemode-selector>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="shop$ | async as shop">
      <button
        class="align-middle text-xs bg-primary py-1 px-2 rounded text-black group hover:bg-secondary transition-all duration-200"
        type="button"
        (click)="showShopSelector()"
      >
        <span class="group-hover:text-white">
          <i class="bi bi-shop"></i>
          {{ shop.geo.postcode }} - {{ "shop.selector.key" | translate }}:
          {{ shop.name }}
          <i class="bi bi-toggles2"></i>
        </span>
      </button>
    </ng-container>
  </div> -->

  <!-- screen md -->
  <div class="flex-row space-x-6 hidden md:flex header-nav">
    <ng-container *ngIf="member$ | async as member">
      <ng-container *ngIf="member.isAdmin">
        <div class="navbar-group group">
          <span class="mr-3">Livemode</span>
          <livemode-selector></livemode-selector>
        </div>
      </ng-container>
    </ng-container>
    <!-- @takeaway menu -->
    <div class="navbar-group group">
      <a
        class="navbar-group-item"
        [routerLink]="['/takeaway']"
        [routerLinkActive]="['text-secondary', 'underline']"
      >
        <span [translate]="'navbar.menu_takeaway'"></span>
      </a>
    </div>

    <!-- @eat in -->
    <!-- <div class="navbar-group group">
      <a
        class="navbar-group-item"
        [routerLink]="['/menu']"
        [routerLinkActive]="['text-secondary', 'underline']"
      >
        <span [translate]="'navbar.menu_eat_in'"></span>
      </a>
    </div> -->

    <!-- @Eat-in -->
    <!-- <div class="navbar-group group">
      <a
        class="navbar-group-item"
        [routerLink]="['/sur-place']"
        [routerLinkActive]="['text-secondary', 'underline']"
      >
        <span [translate]="'navbar.eat_in'"></span>
      </a>
    </div> -->

    <!-- @contact -->
    <div class="navbar-group group">
      <a
        class="navbar-group-item"
        [routerLink]="['/contact']"
        [routerLinkActive]="['text-primary', 'underline']"
      >
        <span translate="navbar.contact"></span>
      </a>
    </div>

    <!-- @blog -->
    <div class="navbar-group group">
      <a
        class="navbar-group-item"
        [routerLink]="['/blog']"
        [routerLinkActive]="['text-primary', 'underline']"
      >
        <span nz-tooltip [nzTooltipTitle]="'navbar.blog' | translate">
          <i class="bi bi-file-earmark-post"></i>
        </span>
      </a>
    </div>

    <!-- @notification -->
    <div class="navbar-group group" *ngIf="notificationCount">
      <nz-badge [nzDot]="notificationCount() ? true : false">
        <a
          class="navbar-group-item"
          [routerLink]="['/notifications']"
          [routerLinkActive]="['text-primary']"
        >
          <span
            nz-tooltip
            [nzTooltipTitle]="
              'navbar.notification' | translate : { count: notificationCount() }
            "
          >
            <i class="bi bi-bell-fill"></i>
          </span>
        </a>
      </nz-badge>
    </div>

    <!-- @cart -->
    <div class="navbar-group group">
      <a
        class="navbar-group-item"
        [routerLink]="['/cart']"
        [routerLinkActive]="['text-primary']"
      >
        <span nz-tooltip [nzTooltipTitle]="'navbar.cart' | translate">
          <i class="bi bi-bag-fill"></i>

          {{ (cart$ | async)?.itemsCount }}
        </span>
      </a>
    </div>

    <!-- @history -->
    <div class="navbar-group group">
      <a
        class="navbar-group-item"
        [routerLink]="['/order/search']"
        [routerLinkActive]="['text-primary']"
      >
        <span nz-tooltip [nzTooltipTitle]="'navbar.order.history' | translate">
          <i class="bi bi-clock-history"></i>
        </span>
      </a>
    </div>

    <!-- @member -->
    <div class="navbar-group group">
      <a
        class="navbar-group-item"
        [routerLink]="['/member']"
        [routerLinkActive]="['text-primary']"
      >
        <span nz-tooltip [nzTooltipTitle]="'navbar.my_account' | translate">
          <i class="bi bi-person-square"></i>
        </span>
      </a>
    </div>

    <!-- @locale -->
    <div class="navbar-group group">
      <button (click)="changeLocale()">
        <span
          nz-tooltip
          [nzTooltipTitle]="'navbar.locale' | translate"
          class="uppercase"
        >
          <i class="bi bi-translate"></i> {{ localeId$ | async }}
        </span>
      </button>
    </div>
  </div>

  <!-- screen sm -->
  <div class="flex flex-row space-x-6 md:hidden text-lg header-nav">
    <!-- @order -->
    <div class="navbar-group group">
      <a
        class="navbar-group-item"
        [routerLink]="['/takeaway']"
        [routerLinkActive]="['text-secondary', 'underline']"
      >
        <span [translate]="'navbar.menu_takeaway'"></span>
      </a>
    </div>

    <!-- @notification -->
    <div class="navbar-group group" *ngIf="notificationCount">
      <nz-badge [nzDot]="notificationCount() ? true : false">
        <a
          class="navbar-group-item btn-lg"
          [routerLink]="['/notifications']"
          [routerLinkActive]="['text-secondary']"
        >
          <i class="bi bi-bell-fill"></i>
        </a>
      </nz-badge>
    </div>

    <!-- @cart -->
    <div class="navbar-group group">
      <a
        class="navbar-group-item btn-lg"
        [routerLink]="['/cart']"
        [routerLinkActive]="['text-secondary']"
      >
        <i class="bi bi-bag-fill"></i>
        {{ (cart$ | async)?.itemsCount }}
      </a>
    </div>

    <!-- @history -->
    <!-- <div class="navbar-group group">
      <a
        [routerLink]="['/order/search']"
        [routerLinkActive]="['text-secondary']"
      >
        <span nz-tooltip [nzTooltipTitle]="'navbar.order.history' | translate">
          <i class="bi bi-clock-history"></i>
        </span>
      </a>
    </div> -->

    <!-- @member -->
    <!-- <div class="navbar-group group">
      <a [routerLink]="['/member']" [routerLinkActive]="['text-secondary']">
        <i class="bi bi-person-square"></i>
      </a>
    </div> -->

    <!-- @sidebar toggle -->
    <div class="navbar-group group">
      <button
        class="btn-lg bg-gradient-to-r from-fuchsia-500 to-pink-500 bg-clip-text text-transparent"
        (click)="showSidebar()"
      >
        <span>
          <i class="bi bi-grid-3x3-gap-fill"></i>
        </span>
      </button>
    </div>
  </div>
</div>
