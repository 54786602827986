import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasRoleDirective } from './directives/has-role/has-role.directive';
import { HasGroupDirective } from './directives/has-group/has-group.directive';
import { ExcludeRoleDirective } from './directives/exclude-role/exclude-role.directive';
import { ExcludeGroupDirective } from './directives/exclude-group/exclude-group.directive';
import { AuthorityGroupResolver, AuthorityRoleResolver } from './resolvers';
import { GroupTagsPipe } from './pipes/group-tags/group-tags.pipe';
import { RoleTagsPipe } from './pipes/role-tags/role-tags.pipe';
import { RbacPreviewComponent } from './components/rbac-preview/rbac-preview.component';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { BelongsToGroupPipe } from './pipes/belongs-to-group/belongs-to-group.pipe';
import { HasRolePipe } from './pipes/has-role/has-role.pipe';
import { RbacMarkerComponent } from './components/rbac-marker/rbac-marker.component';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';

@NgModule({
  declarations: [
    RbacPreviewComponent,
    HasRoleDirective,
    HasGroupDirective,
    ExcludeRoleDirective,
    ExcludeGroupDirective,
    GroupTagsPipe,
    RoleTagsPipe,
    BelongsToGroupPipe,
    HasRolePipe,
    RbacMarkerComponent,


  ],
  imports: [
    CommonModule,

    NzDescriptionsModule,
    NzDividerModule,
    NzTagModule,
    NzIconModule,
    NzTypographyModule,
    NzToolTipModule,
    NzCardModule,
    NzButtonModule,
    NzGridModule,
  ],
  providers: [
    AuthorityGroupResolver,
    AuthorityRoleResolver
  ],
  exports: [
    RbacPreviewComponent,
    HasRoleDirective,
    HasGroupDirective,
    ExcludeRoleDirective,
    ExcludeGroupDirective,
    GroupTagsPipe,
    RoleTagsPipe,
    BelongsToGroupPipe,
    HasRolePipe,
    RbacMarkerComponent,
  ]
})
export class AclCoreModule { }
