import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AclService } from '../../acl.service';
import { IAuthorityRole } from '../../interfaces';

@Injectable({
  providedIn: 'root'
})
export class AuthorityRoleResolver implements Resolve<IAuthorityRole[]> {
  constructor(private aclService: AclService) {

  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IAuthorityRole[]> {
    return this.aclService.role_search({}).pipe(
      map(res => {
        let roles: IAuthorityRole[] = [];
        if (res?.data) {
          roles = res.data as IAuthorityRole[];
        }
        return roles;
      })
    )
  }
}
