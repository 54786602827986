<div class="flex flex-col">
  <!-- Logo -->
  <div class="mx-auto mb-4">
    <a [routerLink]="['/']">
      <img src="images/logo.png" alt="" class="h-16 md:w-20" />
    </a>
  </div>

  <div class="sidebar-nav">
    <!-- @takeaway menu -->
    <div class="navbar-group group">
      <a
        class="sidebar navbar-group-item text-primary"
        [routerLink]="['/takeaway']"
        [routerLinkActive]="['text-primary', 'underline']"
      >
        <span class="mr-1"> <i class="bi bi-book"></i> </span>
        <span [translate]="'navbar.menu_takeaway'"></span>
      </a>
    </div>

    <!-- @menu -->
    <!-- <div class="navbar-group group">
      <a
        class="navbar-group-item"
        [routerLink]="['/menu']"
        [routerLinkActive]="['text-secondary', 'underline']"
      >
        <span class="mr-1">
          <i class="bi bi-book"></i>
        </span>
        <span [translate]="'navbar.menu_eat_in'"></span>
      </a>
    </div> -->

    <!-- @Eat-in -->
    <!-- <div class="navbar-group group">
      <a
        class="navbar-group-item"
        [routerLink]="['/sur-place']"
        [routerLinkActive]="['text-secondary', 'underline']"
      >
        <span class="mr-1">
          <i class="bi bi-cup-hot-fill"></i>
        </span>
        <span [translate]="'navbar.eat_in'"></span>
      </a>
    </div> -->

    <!-- @contact -->
    <div class="navbar-group group">
      <a
        class="navbar-group-item"
        [routerLink]="['/contact']"
        [routerLinkActive]="['text-primary', 'underline']"
      >
        <span class="mr-1">
          <i class="bi bi-shop"></i>
        </span>

        <span translate="navbar.contact"></span>
      </a>
    </div>

    <!-- @blog -->
    <div class="navbar-group group">
      <a
        class="navbar-group-item"
        [routerLink]="['/blog']"
        [routerLinkActive]="['text-primary', 'underline']"
      >
        <span class="mr-1">
          <i class="bi bi-file-earmark-post"></i>
        </span>
        <span [translate]="'navbar.blog'"> </span>
      </a>
    </div>

    <!-- @notification -->
    <div class="navbar-group group">
      <a
        class="navbar-group-item"
        [routerLink]="['/notifications']"
        [routerLinkActive]="['text-primary']"
      >
        <span class="mr-1">
          <i class="bi bi-bell-fill"></i>
        </span>
        <span
          [translate]="'navbar.notification'"
          [translateParams]="{ count: notificationCount() }"
        ></span>
      </a>
    </div>

    <!-- @history -->
    <div class="navbar-group group">
      <a
        class="navbar-group-item"
        [routerLink]="['/order/search']"
        [routerLinkActive]="['text-primary']"
      >
        <span class="mr-1">
          <i class="bi bi-clock-history"></i>
        </span>

        <span>
          {{ "navbar.order.history" | translate }}
        </span>
      </a>
    </div>

    <!-- @member -->
    <div class="navbar-group group">
      <a
        class="navbar-group-item"
        [routerLink]="['/member']"
        [routerLinkActive]="['text-primary']"
      >
        <span class="mr-1">
          <i class="bi bi-person-square"></i>
        </span>
        <span>
          {{ "navbar.my_account" | translate }}
        </span>
      </a>
    </div>

    <!-- @cart -->
    <div class="navbar-group group">
      <a
        class="navbar-group-item"
        [routerLink]="['/cart']"
        [routerLinkActive]="['text-primary']"
      >
        <span class="mr-1">
          <i class="bi bi-bag-fill"></i>
        </span>
        <span>
          {{ "navbar.cart" | translate }} :
          {{ (cart$ | async)?.itemsCount }}
        </span>
      </a>
    </div>

    <!-- @locale -->
    <div class="navbar-group group">
      <button class="navbar-group-item" (click)="changeLocale()">
        <span
          nz-tooltip
          [nzTooltipTitle]="'navbar.locale' | translate"
          class="uppercase"
        >
          <span class="mr-1">
            <i class="bi bi-translate"></i>
          </span>
          <span>
            {{ "locale.language.title" | translate }} : {{ localeId$ | async }}
          </span>
        </span>
      </button>
    </div>
  </div>

  <div class="sidebar-support-nav">
    <div class="navbar-group group">
      <ng-container *ngIf="shop$() as shop">
        <button
          class="btn btn-primary btn-shaped transition-all duration-200"
          type="button"
          (click)="showShopSelector()"
        >
          <span class="">
            <i class="bi bi-shop"></i>
            {{ shop.geo.postcode }} - {{ "shop.selector.key" | translate }}:
            {{ shop.name }}
            <i class="bi bi-toggles2"></i>
          </span>
        </button>
      </ng-container>
    </div>

    <ng-container *ngIf="member$ | async as member">
      <ng-container *ngIf="member.isAdmin">
        <div class="navbar-group group">
          <span class="mr-3">Livemode</span>
          <livemode-selector></livemode-selector>
        </div>
      </ng-container>
    </ng-container>

    <!-- @cdv -->
    <div class="navbar-group group">
      <a
        class="text-slate-400"
        [routerLink]="['/blog/cdv']"
        [routerLinkActive]="['text-primary']"
      >
        <span class="mr-1">
          <i class="bi bi-file-text"></i>
        </span>
        <span>
          {{ "navbar.cdv" | translate }}
        </span>
      </a>
    </div>

    <!-- @allergy -->
    <div class="navbar-group group">
      <a
        class="text-slate-400"
        [routerLink]="['/blog/allergy-ingredients-list']"
        [routerLinkActive]="['text-primary']"
      >
        <span class="mr-1">
          <i class="bi bi-file-earmark-pdf"></i>
        </span>
        <span>
          {{ "home.landing.anchor_allergy_statement" | translate }}
        </span>
      </a>
    </div>

    <!-- Payment Icons -->
    <div>
      <img src="images/onlinebetalengroot.png" alt="" class="w-40" />
    </div>

    <div class="mt-10 text-slate-400">
      <div>
        &copy;
        <span
          [translate]="'app.copyrights'"
          [translateParams]="{ name: 'Sushi Moon' }"
        ></span>
      </div>
      <div>
        &copy;
        <span
          [translate]="'app.powered_by'"
          [translateParams]="{ name: 'WaiMai.io' }"
        ></span>
      </div>
      <div>&hearts; App Vers. : {{ appVersion }}</div>
    </div>
  </div>
</div>
