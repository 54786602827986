import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AclService } from '../../acl.service';
import { IAuthorityGroup } from '../../interfaces';

@Injectable({
  providedIn: 'root'
})
export class AuthorityGroupResolver implements Resolve<IAuthorityGroup[]> {
  constructor(private aclService: AclService) {

  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IAuthorityGroup[]> {
    return this.aclService.group_search({}).pipe(
      map(res => {
        let groups: IAuthorityGroup[] = [];
        if (res?.data) {
          groups = res.data as IAuthorityGroup[];
        }
        return groups;
      })
    )
  }
}
