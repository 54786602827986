<nz-switch
  nzSize="small"
  [ngModel]="livemode$ | async"
  [nzCheckedChildren]="checkedTemplate"
  [nzUnCheckedChildren]="unCheckedTemplate"
  (ngModelChange)="onChange($event)"
  class="livemode-switch"
></nz-switch>
<ng-template #checkedTemplate>
  <span nz-icon nzType="check"></span>
  <span *ngIf="textShow">prod</span>
</ng-template>
<ng-template #unCheckedTemplate>
  <span *ngIf="textShow">test</span>
  <span nz-icon nzType="code"></span>
</ng-template>
