import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  HostListener,
  inject,
  ChangeDetectorRef,
  DestroyRef,
  PLATFORM_ID,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { NavigationEnd, Router } from "@angular/router";
import { ApiStatusService } from "@app/api/status";
import { windowScrollToTopFn } from "@app/helpers/common/functions";
import { isPlatformBrowser } from "@angular/common";
@Component({
  selector: "div[header]",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  @HostListener("window:scroll", ["$event"])
  onScroll(ev: any) {
    this.initClass();
  }

  cdv = inject(ChangeDetectorRef);
  hideOnTop = false;
  show = true;
  private platformId = inject(PLATFORM_ID);
  private statusService: any;
  private router = inject(Router);
  private destroyRef = inject(DestroyRef);

  constructor() {
    if (isPlatformBrowser(this.platformId)) {
      this.statusService = inject(ApiStatusService);
      this.watchRoute();
    }
  }

  ngOnInit(): void {}

  private watchRoute() {
    this.router.events
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if (["/", "/accueil", "/home"].includes(event.url)) {
            this.hideOnTop = false;
          } else {
            this.hideOnTop = false;
          }
          if (isPlatformBrowser(this.platformId)) {
            setTimeout(() => {
              windowScrollToTopFn();
            }, 500);
            this.initClass();
          }
        }
      });
  }

  private initClass(): void {
    const y = window.scrollY;

    if (this.hideOnTop) {
      if (y > 300 && !this.show) {
        this.show = true;
      }
      if (y <= 300 && this.show) {
        this.show = false;
      }
    }

    if (!this.hideOnTop) {
      this.show = true;
    }
    this.cdv.detectChanges();
  }
}
