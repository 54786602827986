import { Injectable } from "@angular/core";
import * as _ from "lodash";

import { Observable, of } from "rxjs";
import { AuthCoreService } from "../auth";
import { catchError, map, switchMap } from "rxjs/operators";
import { ACL } from "./constants";
import { environment } from "@environments/environment";
import { HttpHelperService } from "@app/helpers/http";
import { IHttpResponse } from "@app/helpers/http/interfaces";

@Injectable({
  providedIn: "root",
})
export class AclService {
  #endPoint: string;
  constructor(
    private AuthCoreService: AuthCoreService,
    private http: HttpHelperService
  ) {
    this.#endPoint = environment.coreEndPoint;
  }

  /**
   * Check if current user have permission in params.
   * @param permission string   Permission to check.
   * @return boolean    True if has permission.
   */
  can(permission: string): Observable<boolean> {
    return this.AuthCoreService.authUser$.pipe(
      map((user) => {
        const roles = ACL[permission];
        if (!roles) {
          return false;
        }
        if (_.indexOf(roles, "*") >= 0) {
          return true;
        }

        const codes = user.roles.map((role) => role.code);
        const hasRoles = _.intersection(
          codes.map((code) => code.toString()),
          roles
        );
        return hasRoles.length > 0 ? true : false;
      })
    );
  }

  isRole(roles: string[]): Observable<boolean> {
    if (!roles.length) {
      return of(true);
    }
    return this.AuthCoreService.user_watch().pipe(
      switchMap((authUser) => {
        if (!authUser) {
          return of(false);
        }

        const hasRole = authUser.hasRole(roles);
        return of(hasRole);
      })
    );
  }

  isGroup(groups: string[]): Observable<boolean> {
    if (!groups.length) {
      return of(true);
    }
    return this.AuthCoreService.user_watch().pipe(
      switchMap((authUser) => {
        if (!authUser) {
          return of(false);
        }

        const hasGroup = authUser.hasGroup(groups);
        return of(hasGroup);
      })
    );
  }

  group_search(body: any): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/authorizations/groups/search`;
    return this.http.post(path, body).pipe(catchError(this.http.catch()));
  }

  role_search(body: any): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/authorizations/roles/search`;
    return this.http.post(path, body).pipe(catchError(this.http.catch()));
  }
}
