import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
  HostBinding,
} from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NzDrawerRef, NzDrawerService } from "ng-zorro-antd/drawer";
import { NzModalService } from "ng-zorro-antd/modal";
import { Observable, of, Subject, switchMap, takeUntil } from "rxjs";
import { AuthCoreService } from "@app/api/auth";
import { ICart } from "@app/api/cart/interfaces";
import { NotificationsApiService } from "@app/api/notifications";
import { ShopApiService } from "@app/api/shop";
import { ShopSelectorComponent } from "@app/components/shop";
import { LocaleSelectorComponent } from "@app/helpers/translate-helper/components";
import { SupportedLocaleCodeType } from "@app/helpers/translate-helper/interfaces";
import { environment } from "@environments/environment";

@Component({
  selector: "side-bar",
  templateUrl: "./side-bar.component.html",
  styleUrls: ["./side-bar.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideBarComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  @Input() localeId$: Observable<SupportedLocaleCodeType> | null = null;
  @Input() cart$: Observable<ICart> | null = null;
  notificationCount = this.notificationsService.total;
  shop$ = this.shopService.shop$;
  member$ = this.authService.authUser$;
  appVersion: string;
  @HostBinding("attr.app-version") appVersionAttr;
  constructor(
    private shopService: ShopApiService,
    private notificationsService: NotificationsApiService,
    private drawerRef: NzDrawerRef,
    private drawerService: NzDrawerService,
    private modalService: NzModalService,
    private translateService: TranslateService,
    private authService: AuthCoreService,
    private router: Router
  ) {
    this.appVersion = environment.appVersion;
    this.appVersionAttr = this.appVersion;
  }

  ngOnInit(): void {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe({
      next: (event) => {
        if (event instanceof NavigationStart) {
          this.drawerRef.close();
        }
      },
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  changeLocale(): void {
    const title = this.translateService.instant(
      "locale.language.choose_language"
    );
    this.modalService.create({
      nzTitle: title,
      nzContent: LocaleSelectorComponent,
      nzFooter: [],
    });
    this.drawerRef.close();
  }

  showShopSelector(): void {
    const drawerRef = this.drawerService.create({
      nzContent: ShopSelectorComponent,
      nzContentParams: {
        autoSelect: false,
        syncList: true,
      },
      nzPlacement: "top",
    });

    this.drawerRef.close();

    drawerRef.afterOpen
      .pipe(
        switchMap(() => {
          const component = drawerRef.getContentComponent();
          return component?.event ?? of(false);
        })
      )
      .subscribe({
        next: (data) => {
          if (data) {
            drawerRef.close();
          }
        },
      });
  }
}
