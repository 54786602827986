import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { StateHelperService } from "@app/helpers/state";

@Component({
  selector: "livemode-selector",
  templateUrl: "./livemode-selector.component.html",
  styleUrls: ["./livemode-selector.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LivemodeSelectorComponent implements OnInit {
  @Input() textShow = false;
  livemode$ = this.stateService.livemode$;
  constructor(private stateService: StateHelperService) {}

  ngOnInit(): void {}

  onChange(livemode: boolean): void {
    this.stateService.livemode = livemode;
  }
}
