import { AclType } from "../interfaces";

/**
 * ACL Value.
 */
export const ACL: AclType = {
    home: ['*'],
    post: ['*'],
    'add/post': ['Moderator', 'Administrator'],
    'add/comment': ['Authenticated'],
    admin: ['Administrator'],
};
