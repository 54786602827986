<div class="header-container">
  <div
    class="w-full items-center justify-between"
    [ngClass]="show ? 'flex flex-row' : 'hidden'"
  >
    <!-- Logo -->
    <div class="ml-2 py-2">
      <a [routerLink]="['/']">
        <img
          src="images/logo.png"
          alt=""
          class="h-10 md:h-10 md:w-15 rounded-md"
        />
        <span class="hidden md:inline-block text-primary text-lg ml-2">
          SUSHI MOON
        </span>
      </a>
    </div>

    <!-- Navbar -->
    <div class="py-2">
      <nav-bar></nav-bar>
    </div>
  </div>
</div>
